import "owl.carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "./static/birds.jpg";
import "./static/birds2.jpg";
import "./static/wave.png";
import "./static/404.jpg";
import "./static/logo.png";

import "./homepage.css";
import throttle from '../../lib/throttle';

// FIXE
// document.addEventListener("turbolinks:load", function () {
$(document).ready(function() {
  $(".home__promo_carousel").owlCarousel({
    items: 1,
    nav: true,
    loop: true,
    dots: false,
    navContainer: ".promo__slider_nav",
    navText: [
      "<span class='promo__slider_prev'><i class='fa fa-angle-left' aria-hidden='true'></i></span>",
      "<span class='promo__slider_next'><i class='fa fa-angle-right' aria-hidden='true'></i></span>",
    ],
  });

  $(".hero_carousel").owlCarousel({
    stagePadding: 0,
    margin: 0,
    singleItem: true,
    items: 1,
    nav: true,
    loop: true,
    dots: true,
    navContainer: ".hero_slider__nav",
    navText: [
      "<span class='promo__slider_prev'><i class='fa fa-angle-left' aria-hidden='true'></i></span>",
      "<span class='promo__slider_next'><i class='fa fa-angle-right' aria-hidden='true'></i></span>",
    ],
    responsive: {
      768: {
        dots: false,
      },
    },
  });

  $(".images__carousel").owlCarousel({
    items: 1,
    loop: true,
    nav: false,
    dots: true,
    navContainer: ".images__nav",
    navText: [
      "<span class='images__slider_prev'><i class='fa fa-angle-left' aria-hidden='true'></i></span>",
      "<span class='images__slider_next'><i class='fa fa-angle-right' aria-hidden='true'></i></span>",
    ],
    responsive: {
      768: {
        items: 3,
        nav: true,
        loop: true,
        dots: false,
      },
    },
  });
  $(".slider").owlCarousel({
    items: 1,
    nav: true,
    loop: true,
    dots: false,
    navContainer: ".hero_slider__nav",
    navText: [
      "<span class='promo__slider_prev'><i class='fa fa-angle-left' aria-hidden='true'></i></span>",
      "<span class='promo__slider_next'><i class='fa fa-angle-right' aria-hidden='true'></i></span>",
    ],
  });

  $(".footer_carousel").owlCarousel({
    loop: true,
    dots: false,
    slideBy: 1,
    autoWidth: true,
  });

  function fixHeader() {
    if ($(this).scrollTop() > 150) {
      $(".home__header").fadeIn();
      $(".home__header").addClass("home__header-fixed");
      $(".toggle_menu__item").addClass("toggle_menu__item-white");
      $(".home__btn-mobile").hide();
    } else {
      $(".home__header").removeClass("home__header-fixed");
      $(".toggle_menu__item").removeClass("toggle_menu__item-white");
      $(".home__btn-mobile").show();
    }
  }

  $(window).scroll(throttle(fixHeader, 200));

  $(".home__info_mobile").owlCarousel({
    items: 1,
    loop: true,
    nav: false,
    dots: true,
  });

  $(".mailchimp_submit").on("click", function (e) {
    setTimeout(function () {
      $(".mailchimp_popup").fadeOut("slow");
    }, 3500);
  });
});

// document.addEventListener("turbolinks:load", function () {
$(document).ready(function() {
  window.dispatchEvent(new Event("resize"));
});

// document.addEventListener("turbolinks:before-cache", function () {
//   $(
//     ".home__promo_carousel, .hero_carousel, .images__carousel, .slider, .home__info_mobile"
//   ).owlCarousel("destroy");
// });
