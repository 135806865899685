import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
import share from "../../lib/share";
import throttle from "../../lib/throttle";

require("jquery");

require("@rails/activestorage").start();

import "@fortawesome/fontawesome-free/css/all.css";

import "../../stylesheets/default/application.sass";
//  build
import "../../components/box/box";
import "../../components/breadcrumbs/breadcrumbs";
import "../../components/btn/btn";
import "../../components/content_header/content_header";
import "../../components/donate/donate";
import "../../components/donate_now/donate_now";
import "../../components/explore_birding/explore_birding";
import "../../components/faq/faq";
import "../../components/footer/footer";
import "../../components/header/header";
import "../../components/header_mobile/header_mobile";
import "../../components/homepage/homepage";
import "../../components/image_collection/image_collection";
import "../../components/location_popup/location_popup";
import "../../components/membership/membership";
import "../../components/navigation/navigation";
import "../../components/policy/policy";
import "../../components/post/post";
import "../../components/resources/resources";
import "../../components/search/search";
import "../../components/social/social";
import "../../components/submenu/submenu";
import "../../components/trip_leaders/trip_leaders";



Rails.start();
// Turbolinks.start();

require.context("../../../app/assets/images", true);

$(document).ready(function() {
  $(".archive__title").click(function (event) {
    $(".archive__submenu").toggleClass("archive__submenu-open");
    $(".content__left").toggleClass("content__left-height");
    $(".submenu").toggleClass("submenu-open");
  });

  $(".wysiwyg a").each(function () {
    $(this).attr("data-turbolinks", false);
  });

  $(".wysiwyg abbr").each(function () {
    if ($(this).text().length > 0) {
      $(this).attr("id", $(this).text().trim());
    }
  });

  $("img").each(function () {
    const altText = $(this).attr("alt");
    if (altText !== "") {
      $(this).after($('<div class="image__alt">').html($(this).attr("alt")));
    }
  });

  $(function () {
    $(".footer__mobile_anchor").on("click", function (e) {
      $("html,body")
        .stop()
        .animate({ scrollTop: $("body").offset().top }, 1000);
      e.preventDefault();
    });
  });

  $(".submenu_toggle").click(function () {
    $(".content__left").toggleClass("content__left-open");
    $(".submenu_toggle__icon").toggleClass("subm  enu_toggle__icon-open");
    if ($(".content__left").hasClass("content__left-open")) {
      $("body").addClass("not_scroll");
    } else {
      $("body").removeClass("not_scroll");
    }
  });

  $(".location_popup__btn-toggle").click(function () {
    $(".location_wrapper").toggleClass("location_popup-open");
    $("body").toggleClass("body_overflow-hidden");
    $(".popup_bg").toggleClass("popup_bg-open");
    $(".container").toggleClass("container-index");
    $(".header, .social, .content, .breadcrumbs, .submenu_toggle").toggleClass(
      "blur"
    );
  });

  $(document).mouseup(function (e) {
    var block = $(".location_popup");
    if (!block.is(e.target) && block.has(e.target).length === 0) {
      block.removeClass("location_popup-open");
      $(".location_wrapper").removeClass("location_popup-open");
      $("body").removeClass("body_overflow-hidden");
      $(".popup_bg").removeClass("popup_bg-open");
      $(".container").removeClass("container-index");
      $(
        ".header, .social, .content, .breadcrumbs, .submenu_toggle"
      ).removeClass("blur");
    }
  });

  $(".location_popup__list .location_popup__item:first").css(
    "border-bottom",
    "2px solid #32a349"
  );
  $(".location_popup__item").click(function () {
    $(".location_popup__item").css("border-bottom", "1px solid #C3C3C3");
    $(this).css("border-bottom", "2px solid #32a349");
  });

  $(function () {
    $(".donation__tabs").on(
      "click",
      ".donation__tab:not(.donation__tab-active)",
      function () {
        $(this)
          .addClass("donation__tab-active")
          .siblings()
          .removeClass("donation__tab-active")
          .closest("div.donation")
          .find("div.donation__body")
          .removeClass("donation__body-active")
          .eq($(this).index())
          .addClass("donation__body-active");
      }
    );
  });

  var $titles = $(".modal-show");
  var $blocks = $(".modal");

  // $blocks.hide();

  // FIsas
  $titles.click(function () {
    var index = $(this).index();
    // $blocks.eq(index).show();

    $(this).find(".modal").toggleClass("modal-open");
    $(".body").toggleClass("hidden");
    $(".modal_bg").toggleClass("modal_bg-open");
  });

  $(".modal_preview").click(function (e) {
    e.stopPropagation();
  });

  $(".modal-show a").click(function (e) {
    $(".modal").removeClass("modal-open");
    $(".body").removeClass("hidden");
    $(".modal_bg").removeClass("modal_bg-open");
  });


  // FAQ category
  $(".js-faq_category").click(function (e) {
    e.preventDefault();

    if($(this).hasClass('btn-active')){
      $(".js-faq_category_item").show();
      $(".js-faq_category").removeClass('btn-active');
    } else {
      $(".js-faq_category_item").show();
      $(".js-faq_category").removeClass('btn-active');

      $(this).addClass('btn-active');
      var category = $(this).data('target');
      var hide = $(".js-faq_category_item").filter(function() {
        return $(this).data("category") !== category
      });
      hide.hide();
    }
  });

});
