import "./static/logo.png";

import "./navigation.css";

// document.addEventListener("turbolinks:load", () => {
$(document).ready(function() {
  const $navItems = $(".nav > ul > .nav__item");
  const $navLinks = $navItems.find("> .nav__link");

  $navLinks.off("click").on("click", function (e) {
    e.preventDefault();
    // $navItems.removeClass("nav__item-active");
    $(this).parent().toggleClass("nav__item-active");
  });

  $(".search__button-open").click(function () {
    $(".search").toggleClass("search-visible");
    $(".search__input").focus();
  });
  $(".search__close, .toggle_menu__search-mobile").click(function () {
    $(".search").removeClass("search-visible");
  });
  $(document).click(function (e) {
    if ($(e.target).closest(".navigation, .search").length) {
      return;
    }
    $(".search").removeClass("search-visible");
  });
});
