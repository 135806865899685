import "./header_mobile.css";

// FIXED LATER!!
// $(document).on("turbolinks:load", function () {
$(document).ready(function() {
  $(".toggle_menu").click(function () {
    $(".toggle_menu__item").toggleClass("toggle_menu__item-active ");
    $(".home__header").toggleClass("home__header-fixed");
    $(".navigation").toggleClass("navigation__mobile-open");
    $("body").toggleClass("overflow-hidden");
    $(".header").toggleClass("header-height");
    $(".header__title").toggleClass("header__title-none");
    $(".home__btn").toggleClass("home__btn-none");
    $(".header__mobile_icon").toggleClass("header__mobile_icon-open ");
    $(".search").removeClass("search-visible");
  });
  $(".nav__link").click(function () {
    $(".toggle_menu__item").removeClass("toggle_menu__item-active ");
    $(".home__header").removeClass("home__header-fixed");
    $(".navigation").removeClass("navigation__mobile-open");
    $("body").removeClass("overflow-hidden");
    $(".header").removeClass("header-height");
    $(".header__title").removeClass("header__title-none");
    $(".home__btn").removeClass("home__btn-none");
    $(".header__mobile_icon").removeClass("header__mobile_icon-open ");
  });
});
