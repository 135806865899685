import throttle from '../../lib/throttle';
import "./header.css";

// document.addEventListener("turbolinks:load", () => {
$(document).ready(function() {
  function fixHandler() {
    if ($(this).scrollTop() > 150) {
      $(".header").addClass("header-scroll");
      $(".navigation").addClass("navigation-fixed");
      $(".content__left").addClass("content__left-scroll");
    } else {
      $(".header").removeClass("header-scroll");
      $(".navigation").removeClass("navigation-fixed");
      $(".content__left").removeClass("content__left-scroll");
    }
  }

  $(window).scroll(throttle(fixHandler, 200));

});
