import "./static/footer_logo.png";
import "./static/acredited.png";
import "./static/star.png";
import "./static/location.png";
import "./static/message.png";
import "./static/phone.png";
import "./static/tagline.png";
import "./static/placeholder.svg";

import "./footer.css";
