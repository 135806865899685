import "./static/home.png";

import "./breadcrumbs.css";

// document.addEventListener("turbolinks:load", () => {
//   const body = document.getElementsByClassName("body")[0];
//   const gamburger = document.getElementsByClassName("js-gamburger")[0];
//   const menu = document.getElementsByClassName("js-navbar__menu")[0];

//   gamburger.addEventListener("click", function () {
//     this.classList.toggle("navbar__gamburger-active");
//     menu.classList.toggle("navbar__menu-active");
//     body.classList.toggle("body-overflowed");
//   });
// });
